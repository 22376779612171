<template>
    <div class="project">
        <h2>{{ project.label }}</h2>
        <div :style="background" class="image"></div>
        <p>{{ $tr[project.description] }}</p>
        <nav>
            <div>
                <fubu-button
                    v-if="project.detail"
                    :href="project.detail"
                    :icon="'code'"
                    >{{ $tr.detail }}</fubu-button
                >
                <fubu-button
                    v-if="project.url && project.url != ''"
                    :href="url"
                    target="_blank"
                    :icon="'external-link'"
                    >{{ $tr.visit }}</fubu-button
                >
                <fubu-button
                    v-if="project.github && project.github != ''"
                    :href="project.github"
                    target="_blank"
                    :icon="'brand-github'"
                    >{{ $tr.github }}</fubu-button
                >
                <fubu-button
                    v-if="project.file && project.file != ''"
                    :href="file"
                    download
                    target="_blank"
                    :icon="'download'"
                    >{{ $tr.download }}</fubu-button
                >
            </div>
            <ul>
                <li
                    v-for="category in project.categories"
                    :key="category.id"
                    @click="$emit('goToCategory', category.id)"
                >
                    {{ $tr[category.label] }}
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import FubuButton from "./UI/fubuButton.vue";

export default {
    inject: ["$tr"],
    props: ["project"],
    emits: ["goToCategory"],
    components: { FubuButton },
    computed: {
        background() {
            if (this.project.picture && this.project.picture !== "") {
                let url = "./ressources" + this.project.picture.slice(1);
                console.log(url)
                return {
                    backgroundImage: "url(" + url + ")",
                };
            } else {
                return {};
            }
        },
        url() {
            if (this.project.url.startsWith("./")) {
                return "./ressources" + this.project.url.slice(1);
            }
            return this.project.url;
        },
        file() {
            if (this.project.file.startsWith("./")) {
                return "./ressources" + this.project.file.slice(1);
            }
            return this.project.file.startsWith("./");
        },
    },
};
</script>

<style scoped>
.project {
    border: 1px solid var(--color-0);
    background-color: var(--background-1);
}

.project > :not(.image) {
    margin: 0 1rem;
}

p {
    margin-top: -4rem !important;
    padding: 0.5rem;
    background-color: var(--background-0a8);
}

.image {
    height: 12rem;
    background-repeat: no-repeat;
    background-size: cover;
}

nav > * {
    display: flex;
    flex-flow: wrap;
    column-gap: 0.5rem;
    row-gap: 0.3rem;
    margin: 0.5rem 0;
    padding: 0.5rem;
}

nav > ul {
    border-top: 1px solid var(--color-0);
    margin-bottom: 0;
}

nav li:hover {
    cursor: pointer;
    text-shadow: 0 0 1px var(--color-0);
}
</style>