import tr from "./translations.js";

export default {
    axios: require("axios"),
    baseURL: process.env.VUE_APP_API_URL,
    ftoken: undefined,
    demo: undefined,

    // Main

    connectRequest(key) {
        let api_credentials = { "key": key, user: key === undefined ? undefined : "admin" };
        //console.log("axios.post", this.baseURL + "/connect")
        return this.axios.post(this.baseURL + "/connect", api_credentials)
            .then((response) => {
                this.ftoken = response.data.response
                this.demo = key === undefined;
                return (response);
            }).catch(function (err) {
                console.log(err);
                return (err);
            });
    },

    disconnect() {
        //console.log("axios.post", this.baseURL + "/disconnect?ftoken=" + this.ftoken)
        return this.axios.post(this.baseURL + "/disconnect?ftoken=" + this.ftoken)
            .then(() => {
                this.ftoken = undefined;
            }).catch(function (err) {
                console.log(err);
            });
    },

    uploadFile(tag_id, folder = "") {
        var formData = new FormData();
        var file = document.querySelector('#' + tag_id);
        formData.append("file", file.files[0]);
        //console.log("axios.post", this.baseURL + "/file?ftoken=" + this.ftoken + "&folder=" + folder)
        return this.axios.post(this.baseURL + "/file?ftoken=" + this.ftoken + "&folder=" + folder, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    loadCategoriesAndProject(outCategories, outProjects, with_other) {
        return this.axios
            .all([
                this.getCategoriesRequest(),
                this.getProjectsRequest(),
            ])
            .then(this.axios.spread((categories, projects) => {
                outCategories.length = 0;

                if (with_other) {
                    categories.data.response.push({ id: 0, label: "category_other" })
                }

                for (let i = 0; i < categories.data.response.length; i++) {
                    categories.data.response[i].projects = [];
                    outCategories.push(categories.data.response[i]);
                }

                outProjects.length = 0;
                for (let i = 0; i < projects.data.response.length; i++) {
                    if (with_other && projects.data.response[i].categories.length === 0) {
                        projects.data.response[i].categories.push(0);
                    }

                    outProjects.push(projects.data.response[i]);
                }

                outProjects.forEach((project) => {
                    this.link(project, "categories", outCategories, "projects")
                });

                return (categories, projects)
            }))
    },

    link(item, item_key, elements, element_key) {
        // Remove item from all elements
        elements.forEach(element => {
            for (let index = element[element_key].length - 1; index >= 0; --index) {
                if (element[element_key][index].id == item.id) {
                    element[element_key].splice(index, 1);
                }
            }
        });

        // Add item into relevant elements
        for (
            let index = 0;
            index < item[item_key].length;
            index++
        ) {
            item[item_key][index] = elements.find(
                (element) => element.id == item[item_key][index]
            );
            if (element_key != undefined) {
                item[item_key][index][element_key].push(item);
            }
        }
    },

    // Files

    getFilesRequest(folder = "") {
        return this._getItemsRequest("file", "&folder=" + folder)
    },

    // Projects

    getProjectsRequest() {
        return this._getItemsRequest("project")
    },

    postProjectRequest(project, translations) {
        return this._postItemRequest("project", project, translations)
    },

    deleteProjectRequest(project, translations) {
        return this._deleteItemRequest("project", project, translations)
    },

    // Categories

    getCategoriesRequest() {
        return this._getItemsRequest("category")
    },

    postCategoryRequest(category, translations) {
        return this._postItemRequest("category", category, translations)
    },

    deleteCategoryRequest(category, translations) {
        return this._deleteItemRequest("category", category, translations)
    },

    // Translations

    getTranslationsRequest() {
        return this._getItemsRequest("translation")
    },

    // Locale

    _getItemsRequest(type, params = "") {
        // console.log("axios.get", this.baseURL + "/" + type + "?ftoken=" + this.ftoken + params)
        return this.axios.get(this.baseURL + "/" + type + "?ftoken=" + this.ftoken + params);
    },

    _preparePostItemRequest(type, item) {
        if (item.id === undefined) {
            //console.log("axios.post", this.baseURL + "/" + type + "?ftoken=" + this.ftoken)
            return this.axios.post(this.baseURL + "/" + type + "?ftoken=" + this.ftoken, item);
        } else {
            //console.log("axios.put", this.baseURL + "/" + type + "/" + item.id + "?ftoken=" + this.ftoken)
            return this.axios.put(this.baseURL + "/" + type + "/" + item.id + "?ftoken=" + this.ftoken, item);
        }
    },

    _postItemRequest(type, item, translations) {
        if (this.demo) {
            if (translations != undefined && translations.length > 0) {
                return this._prepareDemoPromise(item).then((savedItem) => {
                    let promises = [savedItem];
                    promises.concat(this._preparePostTranslations(translations, savedItem.data.response[0].id));
                    return this.axios.all(promises);
                })
            } else {
                return this._prepareDemoPromise(item);
            }
        } else {
            if (translations != undefined && translations.length > 0) {
                return this._preparePostItemRequest(type, item)
                    .then((savedItem) => {
                        let promises = [savedItem];
                        promises.concat(this._preparePostTranslations(translations, savedItem.data.response[0].id));
                        return this.axios.all(promises);
                    })
            } else {
                return this._preparePostItemRequest(type, item)
            }
        }
    },

    _deleteItemRequest(type, item, translations) {
        if (item.id === undefined) {
            return this._deleteUndefinedPromise(item);
        } else if (this.demo) {
            return this._prepareDemoPromise(item);
        } else {
            if (translations != undefined && translations.length > 0) {
                //console.log("axios.delete", this.baseURL + "/" + type + "/" + item.id + "?ftoken=" + this.ftoken)
                return this.axios.delete(this.baseURL + "/" + type + "/" + item.id + "?ftoken=" + this.ftoken, item)
                    .then((deletedItem) => {
                        let promises = [deletedItem];
                        promises.concat(this._prepareDeleteTranslations(translations, deletedItem.data.response[0].id));
                        return this.axios.all(promises);
                    })
            } else {
                //console.log("axios.delete", this.baseURL + "/" + type + "/" + item.id + "?ftoken=" + this.ftoken)
                return this.axios.delete(this.baseURL + "/" + type + "/" + item.id + "?ftoken=" + this.ftoken, item);
            }
        }
    },

    // Translations

    _preparePostTranslations(translations, id) {
        let promises = [];
        translations.forEach(translation => {
            translation.$build(id);
            translation.$prepare();
            promises.push(this._preparePostTranslation(translation));
        });
        return promises;
    },

    _preparePostTranslation(translation) {
        return this._preparePostItemRequest("translation", translation)
            .then((saved) => {
                let value = saved.data.response[0];
                tr.tr[value.label_key] = value[tr.lang.value];
                Object.entries(value).forEach((lang) => {
                    if (lang[0] !== "label_key") {
                        tr.trs[lang[0]][value.label_key] = value[lang[0]];
                    }
                });
            });
    },

    _prepareDeleteTranslations(translations, id) {
        let promises = [];
        translations.forEach(translation => {
            translation.$build(id);
            translation.$prepare();
            promises.push(this._prepareDeleteTranslation(translation));
        });
        return promises;
    },

    _prepareDeleteTranslation(translation) {
        return this.axios.delete(this.baseURL + "/translation/" + encodeURIComponent(translation.label_key) + "?ftoken=" + this.ftoken, translation)
            .then((deleted) => {
                let value = deleted.data.response[0];
                delete tr.tr[value.label_key];
                Object.entries(value).forEach((lang) => {
                    if (lang[0] !== "label_key" && !lang[0].startsWith("$")) {
                        delete tr.trs[lang[0]][value.label_key];
                    }
                });
            });
    },

    // demo

    __id: 0,
    _nextId() {
        return --this.__id;
    },

    _deleteUndefinedPromise(item) {
        return new Promise((resolve) => {
            let response = {
                data: {
                    error: {
                        status: 200,
                        reason: "Ok",
                        detail: null,
                    },
                    response: [
                        item
                    ]
                }
            }
            resolve(response);
        });
    },

    _prepareDemoPromise(item) {
        return new Promise((resolve) => {
            if (item.id === undefined) {
                item.id = this._nextId();
            }
            let response = {
                data: {
                    error: {
                        status: 200,
                        reason: "Ok",
                        detail: null,
                    },
                    response: [
                        item
                    ]
                }
            }
            resolve(response);
        });
    },
}