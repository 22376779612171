<template>
    <div v-if="loaded">
        <fubu-header
            :languages="languages"
            :lang="lang"
            @previousLang="previousLang"
            @nextLang="nextLang"
            @toggleTheme="toggleTheme"
        />
        <fubu-menu :categories="categories" @goToCategory="goToCategory" />
        <fubu-category
            v-for="category in categories"
            :key="category.id"
            :category="category"
            @goToCategory="goToCategory"
        />
    </div>
    <div v-else class="loader">
        <div></div>
    </div>

    <div id="themeSwitchOverlay"></div>
</template>

<script>
import FubuMenu from "./components/fubuMenu.vue";
import FubuCategory from "./components/fubuCategory.vue";
import FubuHeader from "./components/fubuHeader.vue";
import fubuApi from "./api.js";
import fubuTr from "./translations.js";

export default {
    components: { FubuCategory, FubuMenu, FubuHeader },
    name: "Fubuland",
    provide() {
        return {
            $tr: this.tr,
        };
    },

    data() {
        return {
            loaded: false,
            languages: {},
            lang: {},
            tr: fubuTr.getTrObject(),
            categories: Array(),
            projects: Array(),
        };
    },

    methods: {
        previousLang() {
            fubuTr.previousLang();
        },
        nextLang() {
            fubuTr.nextLang();
        },
        toggleTheme(on = undefined) {
            document
                .getElementById("themeSwitchOverlay")
                .classList.toggle("showed", false);
            setTimeout(() => {
                document
                    .getElementById("themeSwitchOverlay")
                    .classList.toggle("showed", true);
            }, 0);
            setTimeout(() => {
                document.body.classList.toggle("dark", on);
            }, 500);
        },

        goToCategory(category_id) {
            let category_element = document.getElementById(
                "category_" + category_id
            );
            let menu_element = document.getElementById("menu");
            let top = category_element.getBoundingClientRect().top;
            top += window.scrollY;
            top -= parseFloat(getComputedStyle(menu_element).height);
            window.scrollTo({
                top,
                left: 0,
                behavior: "smooth",
            });
        },
    },

    mounted() {
        if (
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
            document.body.classList.toggle("dark", true);
        }
        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addEventListener("change", (event) => {
                this.toggleTheme(event.matches);
            });

        fubuTr.init(this.languages, this.lang, this.tr);
        fubuApi.connectRequest().then(() => {
            Promise.all([
                fubuApi.loadCategoriesAndProject(
                    this.categories,
                    this.projects,
                    true
                ),
                fubuTr.loadLangs(this.languages, this.tr),
            ]).then(() => {
                this.loaded = true;
            });
        });
    },
};
</script>

<style scoped>
.loader {
    width: 100vw;
    height: 100vh;
    background-color: var(--background-0);
}

.loader > div {
    width: 5em;
    height: 5em;
    animation: 5s linear infinite reverse both running loaderAnim;
    left: calc(50% - 2.5em);
    top: calc(50% - 2.5em);
    position: absolute;
}

@keyframes loaderAnim {
    0% {
        background-color: aquamarine;
        transform: scale(1, 1) rotate(0);
    }
    25% {
        background-color: limegreen;
        transform: scale(1.5, 1.5) rotate(0.5turn);
    }
    50% {
        background-color: chocolate;
        transform: scale(0.8, 0.8) rotate(1turn);
    }
    75% {
        background-color: plum;
        transform: scale(1.5, 1.5) rotate(1.5turn);
    }
    100% {
        background-color: aquamarine;
        transform: scale(1, 1) rotate(2turn);
    }
}

#themeSwitchOverlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}

#themeSwitchOverlay.showed {
    animation-delay: 0;
    animation-direction: normal;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-name: themeSwitchOverlayAnim;
    animation-iteration-count: 1;
}

@keyframes themeSwitchOverlayAnim {
    0% {
        z-index: 10000;
        opacity: 0;
        background-color: var(--background-0);
    }
    45% {
        opacity: 1;
    }
    50% {
        opacity: 1;
        background-color: #888888;
    }
    55% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        background-color: var(--background-0);
    }
}
</style>
