<template>
    <nav id="menu">
        <input id="smMenuDisplay" type="checkbox" />
        <label for="smMenuDisplay"><span>Menu</span>
            <ul>
                <li v-for="category in categories" :key="category.id" @click="$emit('goToCategory', category.id)">
                    {{ $tr[category.label] }}
                </li>
            </ul>
        </label>
        
    </nav>
</template>

<script>
export default {
    inject:["$tr"],
    props: ["categories"],
    emits: ["goToCategory"],
};
</script>

<style scoped>
nav {
    background-color: var(--background-2);
    position: sticky;
    top: 0;
    z-index: 1000;
}

nav > input {
    display: none;
}
nav > label {
    width: 100%;
}

nav > label > span {
    display: block;
    width: 100%;
    height: 2.5rem;
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
}

nav ul {
    display: none;
    position: absolute;
    top: 2.5rem;
    width:100%;
}

nav ul > li {
    height: 2.2rem;
    background-color: var(--background-1);
    font-weight: 500;
    text-align: center;
    font-size: 1.2rem;
    border-bottom: 1px solid transparent;
}

nav ul > li:hover {
    background-color: var(--background-2);
    text-shadow: 0 0 1px var(--color-0);
}

nav ul > li:last-child {
    border-bottom: 1px solid var(--color-2);
}

nav input:checked ~ label ul {
    display: flex;
    flex-direction: column;
}

@media (min-width: 576px) {
    nav > label {
        cursor: default;
    }

    nav > label > span {
        display: none;
    }

    nav ul,
    nav > input:checked ~ label ul {
        display: flex;
        flex-direction: row;
        position: relative;
        top: 0;
    }

    nav ul > li,
    nav ul > li:last-child {
        cursor: pointer;
        background-color: var(--background-2);
        padding: 0 0.5rem;
        border:0px solid transparent;
        position:relative;
        min-width:3rem;
    }
    
    nav ul::before,
    nav ul > li::before {
        position:absolute;
        display:block;
        content:'';
        background-color:var(--color-2);
        bottom:0rem;
        left:0;
        width:100%;
        height:0.2rem;
    }
    nav ul > li::after {
        position:absolute;
        display:block;
        content:'';
        box-sizing: border-box;
        bottom:0rem;
        left:0;
        box-sizing:border-box;
        height:0rem;
        border-top:0.0rem solid var(--color-2);
        border-left:1.5rem solid transparent;
        border-right:1.5rem solid transparent;
        margin-left:calc(50% - 1.5rem);
        margin-right:calc(50% - 1.5rem);
        transition: bottom 0.5s, border-top 0.5s;
    }
    
    nav ul > li:hover::after {
        border-top:0.5rem solid var(--color-2);
        bottom:-0.5rem;
    }
}
</style>