<template>
    <button v-if="href === undefined" :title="title">
        <span v-if="icon !== undefined" class="ti" :class="'ti-'+icon" :style="{color}"/><slot></slot>
    </button>
    
    <a v-else :href="href" :title="title">
        <span v-if="icon !== undefined" class="ti" :class="'ti-'+icon" /><slot></slot>
    </a>
</template>

<script>
export default {
    props: ["href", "title", "icon", "color"]
}
</script>

<style scoped>
button,
a {
    border: 0.1rem solid var(--color-0);
    padding: 0.05rem 0.55rem;
    background-color: var(--background-0);
    text-decoration: none;
}

button span,
a span {
    margin-right: 0.5rem;
}

button:hover,
a:hover {
    border: 0.15rem solid var(--color-0);
    padding: 0rem 0.5rem;
}

</style>