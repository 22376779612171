<template>
    <header>
        <h1>{{ $tr.welcome }}</h1>
        <img class="lang"
            :title="$tr.lang_switch"
            v-if="lang.value != undefined"
            :src="require('@/assets/flags/' + languages.langs[lang.value].flag)"
            @click.left.exact="nextLang"
            @click.left.shift="previousLang"
        />
        <input type="checkbox" class="theme" @click="$emit('toggleTheme')" :title="$tr.theme_switch">
    </header>
</template>

<script>
export default {
    inject:["$tr"],
    emits:["nextLang", "previousLang", "toggleTheme"],
    props:["languages", "lang"],
    methods: {
        nextLang() {
            this.$emit('nextLang')
        },
        previousLang() {
            this.$emit('previousLang')
        },
    }
};
</script>

<style scoped>
h1 {
    text-align: center;
    margin-left: 3rem;
    margin-right: 3rem;
}

header {
    position:relative;
}

.lang {
    position:absolute;
    top:0.5rem;
    right:0.7rem;
    width:1.6rem;
    height:1.2rem;
    z-index: 1000;
}

.theme {
    --appearance: none;
    position: relative;
    position:absolute;
    top:2rem;
    right:0.5rem;
    width:2rem;
    height:1rem;
    z-index: 1000;
}

.theme::before {
    content: "";
    display: inline-block;
    position: absolute;
    top:0;
    left:0;
    width:2rem;
    height:1rem;
    border-radius: 0.5rem;
    border:0.1rem solid var(--color-0);
    background-color:var(--background-0);
    transition: 0.2s ease-in-out;
}

.theme::after {
    content: "";
    display: inline-block;
    position: absolute;
    top:0.2rem;
    left:0.2rem;
    width:0.6rem;
    height:0.6rem;
    border-radius: 0.3rem;
    background-color:var(--color-0);
    transition: 0.2s ease-in-out;
}

.theme:checked::after {
    left:1.2rem;
}

</style>