<template>
    <section :id="'category_' + category.id">
        <h1>{{ $tr[category.label] }}</h1>
        <div class="grid-category-cols">
            <fubu-project
                v-for="project in category.projects"
                :key="project.id"
                :project="project"
                @goToCategory="goToCategory"
            />
        </div>
    </section>
    <hr />
</template>

<script>
import fubuProject from "./fubuProject.vue";
export default {
    components: { fubuProject },
    inject:["$tr"],
    props: ["category"],
    emits: ["goToCategory"],
    methods: {
        goToCategory(categoryId) {
            this.$emit("goToCategory", categoryId);
        },
    },
};
</script>

<style scoped>
section {
    margin-top: 1rem;
}

h1 {
    margin-left: 2em;
}

hr {
    margin-bottom: 0;
}

.grid-category-cols {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 20rem));
    padding-left: calc((100% - 20rem) / 2);
    padding-right: calc((100% - 20rem) / 2);
    grid-row-gap: 3rem;
}

@media (min-width: 49rem) {
    .grid-category-cols {
        grid-template-columns: repeat(2, 20rem);
        padding-left: calc((100% - 40rem) / 3);
        padding-right: calc((100% - 40rem) / 3);
        grid-column-gap: calc(100% - 40rem);
    }
}
@media (min-width: 72rem) {
    .grid-category-cols {
        grid-template-columns: repeat(3, 20rem);
        padding-left: calc((100% - 60rem) / 4);
        padding-right: calc((100% - 60rem) / 4);
        grid-column-gap: calc((100% - 60rem) / 2);
    }
}
@media (min-width: 95rem) {
    .grid-category-cols {
        grid-template-columns: repeat(4, 20rem);
        padding-left: calc((100% - 80rem) / 5);
        padding-right: calc((100% - 80rem) / 5);
        grid-column-gap: calc((100% - 80rem) / 3);
    }
}
</style>