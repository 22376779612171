import api from "./api.js";

export default {
    tr: undefined,
    languages: undefined,
    lang: undefined,
    trs: {},

    getTrObject() {
        let translations = this;
        const $tr = {
            $build(key) {
                let value = this[key];
                if (value !== undefined) {
                    for (let i = 1; i < arguments.length; i++) {
                        value = value.replace("%1", arguments[i]);
                    }
                }
                return value;
            },

            $getInit(key_item, key_id, key_labels) {
                let values = [];
                key_labels.forEach(key_label => {
                    let value = {
                        key_item,
                        key_label,
                        $build(id) {
                            this.key_id = id === undefined ? 0 : id;
                            this.label_key = `#${this.key_item}#${this.key_id}#${this.key_label}`;
                            this.label_key_default = `#${this.key_item}#0#${this.key_label}`;
                        },
                        $prepare() {
                            delete this.key_id;
                            delete this.key_item;
                            delete this.key_label;
                            delete this.label_key_default;
                        }
                    };
                    value.$build(key_id);
                    values.push(value);
                });

                return values;
            },

            $getLoad(values) {
                let promise = new Promise((resolve, reject) => {
                    let promises = [];
                    values.forEach(value => {
                        Object.entries(translations.languages.langs).forEach((lang) => {
                            if (translations.languages.langs[lang[0]].alias === undefined) {
                                promises.push(
                                    translations.loadLang(lang[0]).then(() => {
                                        let tr = translations.trs[lang[0]];
                                        if (tr === undefined || tr[value.label_key] === undefined) {
                                            if (tr[value.label_key_default] !== undefined) {
                                                value[lang[0]] = tr[value.label_key_default];
                                            } else {
                                                value[lang[0]] = "";
                                            }
                                        } else {
                                            value[lang[0]] = tr[value.label_key]
                                        }
                                    })
                                )
                            }
                        });
                    });
                    Promise.all(promises)
                        .then(() => resolve())
                        .catch(() => reject())

                });
                values.forEach(value => {
                    value.set = promise;
                });
            }
        };

        const tr_handler = {
            get(target, prop) {
                if (
                    typeof prop !== "string" ||
                    target[prop] !== undefined ||
                    prop.startsWith("_")
                ) {
                    return Reflect.get(...arguments);
                }
                return "[" + prop + "]";
            },
        };

        return new Proxy($tr, tr_handler);
    },

    init(languages, lang, tr) {
        this.tr = tr;
        this.lang = lang;
        this.languages = languages;
    },

    loadLangs(languages, tr) {
        let _languages = require("./assets/localization/localization.json");
        Object.assign(this.languages, _languages);
        if (languages != null) {
            Object.assign(languages, _languages);
        }
        return this.setLang(undefined, tr);
    },

    setLang(lang, tr) {
        if (lang === undefined) {
            if (this.languages.langs[navigator.language] != undefined) {
                lang = navigator.language;
            } else {
                lang = this.languages.default;
            }
        }

        if (this.languages.langs[lang].alias != undefined) {
            lang = this.languages.langs[lang].alias;
        }
        this.lang.value = lang;
        console.log("using lang :", this.lang.value);

        return this.loadLang(lang).then(() => {
            Object.getOwnPropertyNames(this.tr).forEach((key) => {
                if (!key.startsWith("_") && !key.startsWith("$")) {
                    delete this.tr[key];
                }
            });
            Object.assign(this.tr, this.trs[lang]);
            if (tr != null) {
                Object.getOwnPropertyNames(tr).forEach((key) => {
                    if (!key.startsWith("_") && !key.startsWith("$")) {
                        delete tr[key];
                    }
                });
                Object.assign(tr, this.trs[lang]);
            }
            this.currentlang = lang;
        });

    },

    loadLang(lang) {
        return new Promise((resolve) => {
            if (this.trs[lang] === undefined) {
                this.trs[lang] = require("./assets/localization/" +
                    lang +
                    "/translations.json");

                api.getTranslationsRequest()
                    .then((translations => {
                        translations.data.response.forEach(translation => {
                            this.trs[lang][translation.label_key] = translation[lang];
                        });
                        resolve();
                    }))
                    .catch((err) => {
                        console.log(err);
                        resolve();
                    })
            }
            else {
                resolve();
            }
        });
    },

    nextLang() {
        let langs = Object.keys(this.languages.langs);
        let currentIndex = langs.findIndex((lang) => lang == this.lang.value);

        let index = currentIndex;
        while (++index != currentIndex) {
            if (index == langs.length) {
                index = 0;
            }
            if (this.languages.langs[langs[index]].alias == undefined) {
                this.lang.value = langs[index];
                this.setLang(this.lang.value);
                return;
            }
        }
    },

    previousLang() {
        let langs = Object.keys(this.languages.langs);
        let currentIndex = langs.findIndex((lang) => lang == this.lang.value);
        let index = currentIndex;
        while (--index != currentIndex) {
            if (index < 0) {
                index = langs.length - 1;
            }
            if (this.languages.langs[langs[index]].alias == undefined) {
                this.lang.value = langs[index];
                this.setLang(this.lang.value);
                return;
            }
        }
    },
}